<template>
  <nav
    :aria-label="messages[locale].ariaLabelNavigation"
    class="lang-switch font-sm-bold-vl font-md-bold-vs"
  >
    <p class="_visually-hidden">
      {{ messages[locale].infoCurrentLanguage }}
    </p>
    <ul class="list" :aria-label="messages[locale].ariaLabelList">
      <li class="item">
        <a
          class="link nav-link-primary -custom"
          :class="{ '-active': locale === 'de' }"
          href="/de"
          lang="de"
          hreflang="de"
          title="Diese Seite auf Deutsch anzeigen"
          >DE</a
        >
      </li>
      <li class="item">
        <a
          class="link nav-link-primary -custom"
          :class="{ '-active': locale === 'en' }"
          href="/en"
          lang="en"
          hreflang="en"
          title="View this page in english"
          >EN</a
        >
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { type Locale } from '@intlify/core-base';

const { locale } = useI18n();

const messages: Record<Locale, Record<string, string>> = {
  en: {
    ariaLabelNavigation: 'Language navigation',
    ariaLabelList: 'Switch language',
    infoCurrentLanguage: `Language navigation. The current language is english`,
  },
  de: {
    ariaLabelNavigation: 'Sprachnavigation',
    ariaLabelList: 'Sprache wechseln',
    infoCurrentLanguage: `Sprachnavigation. Die aktuelle Sprache ist deutsch.`,
  },
};
</script>

<style scoped lang="scss">
.lang-switch {
  height: 100%;

  > .list {
    display: flex;
    gap: 1rem;
    height: 100%;

    @media (--vs) {
      align-items: flex-end;
    }

    > .item {
      display: flex;

      > .link {
        padding-top: 1rem;
        height: 100%;

        @media (--vs) {
          padding-bottom: 1.25rem;
        }
      }
    }
  }
}
</style>
