<template>
  <svg
    class="svg-arrow-button"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <rect
      x="4.86865"
      y="10.5254"
      width="8"
      height="1.6"
      rx="0.8"
      transform="rotate(-45 4.86865 10.5254)"
      fill="currentColor"
    />
    <rect
      x="6"
      y="0.343262"
      width="8"
      height="1.6"
      rx="0.8"
      transform="rotate(45 6 0.343262)"
      fill="currentColor"
    />
    <rect
      x="0.691406"
      y="5.19873"
      width="9.52618"
      height="1.6"
      rx="0.8"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="scss">
.svg-arrow-button {
  user-select: none;
  pointer-events: none;
}
</style>
