<template>
  <footer ref="siteFooterElement" class="footer site-footer">
    <h2 class="_visually-hidden">{{ messages[locale].footer }}</h2>
    <div ref="siteFooterInnerElement" class="inner">
      <div class="block">
        <BlockContentAddress class="block" />
        <SocialMediaLinks class="block" />
      </div>
      <FooterTopMiddleNavigation class="block -topmiddle" />
      <FooterNavigation class="block -footernav" />
      <BlockContentCta class="block -cta" />
      <div class="block -copyright">
        <p class="text">Ⓒ 2024 Zühlke Engineering AG</p>
      </div>
      <div class="block -cookie-settings">
        <!--        todo: link cookie settings -->
        <LinkHandler :path="`/${locale}/cookie-policy`" class="link"
          >Cookie Settings 🍪</LinkHandler
        >
      </div>
      <LangSwitch class="block -lang-switch -customlangfooter" />
    </div>
  </footer>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import type { Locale } from '@intlify/core-base';
import FooterNavigation from '~/components/site-footer/components/FooterNavigation.vue';
import FooterTopMiddleNavigation from '~/components/site-footer/components/FooterTopMiddleNavigation.vue';
import BlockContentAddress from '~/components/site-footer/components/BlockContentAddress.vue';
import SocialMediaLinks from '~/components/site-footer/components/SocialMediaLinks.vue';
import BlockContentCta from '~/components/site-footer/components/BlockContentCta.vue';
import LangSwitch from '~/components/site-header/LangSwitch.vue';
import LinkHandler from '~/components/links/LinkHandler.vue';

const siteFooterElement = ref<null | HTMLElement>(null);
const siteFooterInnerElement = ref<null | HTMLElement>(null);

const { locale } = useI18n();

// const { isVl } = useViewportSize();

const messages: Record<Locale, Record<string, string>> = {
  en: {
    footer: 'Footer',
  },
  de: {
    footer: 'Fusszeile',
  },
};

onMounted(function () {
  if (siteFooterElement.value) {
    initScrollTrigger();
  }
});

function initScrollTrigger() {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: siteFooterElement.value,
      pin: true,
      start: 'top top',
      end: '+=100%',
      // markers: true,
    },
  });
}

// onMounted(function () {
//   if (isVl.value) {
//     initScrollTrigger();
//   }
// });
//
// function initScrollTrigger() {
//   const tl = gsap.timeline({
//     ease: 'none',
//     scrollTrigger: {
//       trigger: siteFooterElement.value,
//       scrub: 0,
//       start: '-120%%',
//       end: '0%',
//     },
//   });
//
//   const lastSectionElement = document.querySelector(
//     '.main >.layout-default > section:last-of-type',
//   );
//
//   tl.fromTo(
//     lastSectionElement,
//     {
//       y: 0,
//     },
//     {
//       y: window.innerHeight * 0.6,
//       ease: 'power1.in',
//     },
//   );
// }
</script>

<style scoped lang="scss">
.site-footer {
  background-color: var(--color-white);
  color: var(--color-topaz-800);
  padding: 0 var(--base-component-padding-inline)
    var(--base-component-padding-block) var(--base-component-padding-inline);
  display: flex;
  align-items: flex-end;
  //position: sticky;
  //bottom: 0;
  //left: 0;
  //z-index: -1;
  position: relative;
  min-height: 100svh;
  margin-top: -100svh;

  @media (--vs) {
    padding-top: 3.5rem;
  }

  /*  @media (--vl) {
    min-height: 100svh;
  }*/

  > .inner {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)) minmax(15rem, 1fr) minmax(
        15rem,
        1fr
      );
    grid-template-rows: 1fr auto;
    gap: 5.8rem 1rem;
    margin: auto 0 0 0;
    align-items: baseline;

    @media (--vs) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 3.5rem 1rem;
    }

    .block {
      height: fit-content;

      &.-topmiddle {
        @media (--vs) {
          grid-column: 1 / 3;
          grid-row: 2 / 3;
        }
      }

      &.-footernav {
        @media (--vs) {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
        }
      }

      &.-cta {
        grid-row: 1 / 2;

        @media (--vs) {
          grid-column: 1 / 3;
        }

        @media (--vl) {
          grid-column: 4 / 6;
        }
      }

      &.-cookie-settings {
        align-self: flex-end;

        @media (--vs) {
          grid-column: 1 / 2;
          grid-row: 4 / 5;
        }

        @media (--vl) {
          grid-column: 4 / 5;
          grid-row: 2 / 3;
        }
      }

      &.-lang-switch {
        justify-self: flex-end;
        width: fit-content;

        @media (--vs) {
          grid-column: 2 / 3;
          grid-row: 4 / 5;
        }

        @media (--vl) {
          grid-column: 5 / 6;
          grid-row: 2 / 3;
        }

        :deep(.-custom) {
          padding: 0;

          &::before {
            width: 0;
          }
        }
      }

      &.-copyright {
        @media (--vs) {
          grid-column: 1 / -1;
          margin-top: -2.69rem;
          color: var(--color-topaz-500);
        }

        @media (--vl) {
          grid-column: 1 / 4;
          grid-row: 2 / 3;
          align-self: flex-end;
        }
      }

      > .link {
        text-decoration: none;
      }

      :deep(> .title) {
        @media (--vs) {
          padding-bottom: 1rem;
        }

        @media (--vl) {
          padding-bottom: 1.5rem;
        }
      }
    }
  }
}
</style>
