<template>
  <a
    v-if="linkType === 'internal'"
    :class="['internal-link', { 'button-primary': isButtonPrimary }]"
    :href="`https://www.zuehlke.com${path}`"
  >
    <slot />
    <SvgArrowButton v-if="isButtonPrimary" />
  </a>
  <a
    v-else
    :class="['internal-link', { 'button-primary': isButtonPrimary }]"
    :href="path"
  >
    <slot />
    <SvgArrowButton v-if="isButtonPrimary" />
  </a>
</template>

<script setup lang="ts">
import SvgArrowButton from '~/components/paragraphs/svgs/SvgArrowButton.vue';

const props = defineProps<{
  path: string;
  isInternal?: boolean;
  isButtonPrimary?: boolean;
}>();

const linkType = computed(function () {
  if (props.isInternal) {
    return 'internal';
  }

  if (props.path.substring(0, 4) === 'http') {
    return 'external';
  } else if (props.path.substring(0, 6) === 'mailto') {
    return 'email';
  } else if (props.path.substring(0, 3) === 'tel') {
    return 'phone';
  }

  return 'internal';
});
</script>

<style scoped lang="scss"></style>
